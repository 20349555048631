import React from 'react'
import * as PropTypes from 'prop-types'
import Link from '../../Navigation/Link/Link'
import { Content, ContentContainer } from '../ContentContainer/ContentContainer'

export default function ImageLinkContainer (props) {
  return <div className='h-100'
    style={{ transform: 'translateY(-150px)' }}>
    <Link to={props.to}
      classNames='link-unstyled h-100'>
      <ContentContainer className='shadow-lg h-100'>
        <Content className='h-100 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center'>
          <img src={props.imgURL}
            className='img-link-icon mb-3 mb-sm-0 mr-0 mr-sm-4 shadow rounded-circle border border-white'
            alt=''
          />
          <div className='text-center text-sm-left'>
            <h4>{props.headline}</h4>
            <p className='mb-0 color-black'>
              {props.text}
            </p>
          </div>
        </Content>
      </ContentContainer>
    </Link>
  </div>
}

ImageLinkContainer.propTypes = {
  headline: PropTypes.string,
  imgURL: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string
}
