import * as PropTypes from 'prop-types'
import React from 'react'

export default function HorizontalLine (props) {
  return <hr className={`w-100 hr hr-${props.color}`} />
}

HorizontalLine.propTypes = {
  color: PropTypes.string.isRequired
}
