import React from 'react'
import * as PropTypes from 'prop-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Col, Container, Row } from '@headtrip/components-react'
import IntroContainer from '../components/Container/IntroContainer/IntroContainer'
import Link from '../components/Navigation/Link/Link'
import SupplementSection from '../components/Various/SupplementSection/SupplementSection'

import { TutorialEntries } from '../components/Tutorials/TutorialEntry/TutorialEntry'
import Header from '../components/Navigation/Header/Header'
import HorizontalLine from '../components/Various/HorizontalLine/HorizontalLine'
import ImageLinkContainer from '../components/Container/ImgLinkContainer/ImageLinkContainer'
import { Content, ContentContainer } from '../components/Container/ContentContainer/ContentContainer'

import headerImage from '../images/community/startpage-header-hetzner-community.webp'
import imgCommunity1 from '../images/community/write-an-article-hetzner-community.png'
import imgCommunity2 from '../images/community/get-rewarded-hetzner-community.png'
import imgCommunity3 from '../images/community/contribute-hetzner-community.png'
import iconTutorials from '../images/community/tutorials.svg'
import iconForum from '../images/community/community.svg'
import sectionBg from '../images/community/what-is-the-community-hetzner-community.jpg'

import FullWidthBgImage from '../components/Various/FullWidthBgImg/FullWidthBgImg'
import { graphql, StaticQuery } from 'gatsby'

const imgLinkContainer = [
  {
    to: '/tutorials',
    imgURL: iconTutorials,
    headline: 'Tutorials',
    text: 'Share your expertise, get rewarded for your writing, support other users, and help to grow a free collection of high-quality tutorials for developers.'
  },
  {
    to: 'https://forum.hetzner.com',
    imgURL: iconForum,
    headline: 'Forum',
    text: 'Connect with other developers and the Hetzner Online Team. Share your ideas and get answers to your questions.'
  }
]

const highlightBlocks = [
  {
    headline: 'Write an Article',
    image: imgCommunity1,
    content: (

      <>
        <p className='font-weight-light lead'>
          Do you have an idea for a high-quality tutorial that is not yet in our
          library and is worth sharing?
        </p>
        <p className='font-weight-light m-0 lead'>
          <strong>Then start writing the tutorial!</strong>
        </p>
      </>
    )
  },
  {
    headline: 'Contribute',
    image: imgCommunity3,
    content: (
      <>
        <p className='font-weight-light lead'>
          If you have any questions or need a hand getting started, we will be happy to help you. If your tutorial meets all the&nbsp;
          <Link
            to='https://github.com/hetzneronline/community-content/blob/master/contributing.md'
          >criteria
          </Link>,
          you can contribute it to our <Link to='https://github.com/hetzneronline/community-content'>GitHub
          Repository
          </Link>.
        </p>
        <p className='font-weight-light m-0  lead'>
          <strong><Link to='https://github.com/hetzneronline/community-content'>Share</Link> your tutorial
            with the community.
          </strong>
        </p>
      </>
    )
  },
  {
    headline: 'Get Rewarded',
    image: imgCommunity2,
    content: (
      <>
        <p className='font-weight-light lead'>
          We'll review your tutorial. If your tutorial fulfills all the&nbsp;
          <Link
            to='https://github.com/hetzneronline/community-content/blob/master/contributing.md'
          >
          criteria
          </Link> and it's relevant for our library, we will publish it.
        </p>
        <p className='font-weight-light m-0 lead'>
          <strong className='d-block mb-4 mb-sm-0'>For every tutorial we publish on Hetzner Community, we will
          give up to <span className='color-green'>€50 in credit</span> on your account!
          </strong>
        </p>
      </>
    )
  }
]

function HighlightBlock (props) {
  if (props.imgRight) {
    return <Row className='align-items-center'>
      <Col xl={6} sm={6} xs={12}>
        <div className='text-center text-sm-left'><h5>{props.headline}</h5>
          {props.children}
        </div>
      </Col>
      <Col xl={6} sm={6} xs={12}>
        <img src={props.image} alt='' className='img-fluid' />
      </Col>
    </Row>
  }
  if (props.imgLeft) {
    return <Row className='align-items-center'>
      <Col xl={6} sm={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
        <img src={props.image} alt='' className='img-fluid' />
      </Col>
      <Col xl={6} sm={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
        <div className='text-center text-sm-left'><h5>{props.headline}</h5>
          {props.children}
        </div>
      </Col>
    </Row>
  }
}

HighlightBlock.propTypes = {
  children: PropTypes.any,
  headline: PropTypes.string,
  image: PropTypes.string,
  imgLeft: PropTypes.bool,
  imgRight: PropTypes.bool
}

class CommunityPage extends React.Component {
  static renderImgLinkContainer () {
    return imgLinkContainer.map((entry, index) => {
      return <Col key={'img-link-' + index} lg={6} xs={12} className='mb-3 mb-lg-0 '>
        <ImageLinkContainer
          to={entry.to}
          imgURL={entry.imgURL}
          headline={entry.headline}
          text={entry.text}
        />
      </Col>
    })
  }

  static getTutorialEntries (data) {
    if (data.allMarkdownRemark === null) return
    const edges = data.allMarkdownRemark.edges
    if (typeof edges === 'undefined' || edges <= 0) return
    return edges.map((edge) => {
      return edge.node.frontmatter
    })
  }

  renderHighlightBlocks () {
    return highlightBlocks.map((entry, index) => {
      const SupplementSection = <HorizontalLine color='grey' />
      const orientation = index % 2 === 0
      const lastItem = highlightBlocks.length === index + 1

      return (
        <>
          <Container key={'hightlight-' + index}>
            <HighlightBlock headline={entry.headline} image={entry.image} imgLeft={!orientation} imgRight={orientation}>
              { entry.content }
            </HighlightBlock>
          </Container>
          {!lastItem && SupplementSection}
        </>
      )
    })
  }

  static getTutorialEntries (data) {
    if (data.allMarkdownRemark === null) return
    const edges = data.allMarkdownRemark.edges
    if (typeof edges === 'undefined' || edges <= 0) return
    return edges.map(edge => {
      return edge.node.frontmatter
    })
  }

  render () {
    return <StaticQuery
      query={graphql`
      query{
        allMarkdownRemark(
         sort: { order: DESC, fields: [frontmatter___date] }
        limit: 10){
          edges{
            node{
              frontmatter{
                lang
                title
                short_description
                author
                tags
                date
                path
                author_img
              }
            }
          }
        }
      }
    `}
      render={tutorialData => (

        <Layout noHeader location={{ pathname: '/' }} supplementColor='grey'>

          <SEO title={'Hetzner Community'} type={'website'} locale={'en'}/>

          <main>
            <Header headerImage={headerImage} className='header-big bg-white'>
              <Container>
                <Row>
                  <Col xs={12} lg={8} xl={8}>
                    <h1 className='mt-0 text-transform-none text-white'>Hetzner Community</h1>
                    <p className='m-0 font-weight-light lead text-white'>
                      <strong>Learn, share and grow! </strong>
                      Discover the Hetzner Community, connect with other enthusiastic
                      experts and share your knowledge and ideas with the community.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Header>
            <section className='py-5 bg-black full-width-bg' style={{ backgroundImage: `url(${ sectionBg })` }}>
              <Container>
                <Row>{ CommunityPage.renderImgLinkContainer() }</Row>
              </Container>
              <Container className='mt-n7 mt-lg-n5 mb-4'>
                <Row>
                  <Col xl={{ span: 10, offset: 1 }}>
                    <div className='text-center'>
                      <h3 className='mt-0 color-white'>What is the Hetzner Community?</h3>
                      <p className='color-white lead font-weight-light'>
                        The heart of Hetzner Community is the tutorial area in addition to our frequently visited
                        forum. The free collection of high-quality tutorials, which are based on free and open source
                        software, includes texts on a variety of topics such as development, system administration, and
                        other web technology.
                      </p>
                      <p className='color-white lead font-weight-light'>
                        We at Hetzner Online GmbH would like to give all users an extra hand so that they get their
                        projects off to a good start. Together with the community, we will continually expand and update
                        the collection.
                      </p>
                      <p className='color-white lead'>
                        <strong>Share your knowledge and get rewarded!</strong>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <SupplementSection color='light-grey' />
            <section className='py-5'>
              <Container>
                <Row>
                  <Col>
                    <IntroContainer>
                      <h2>Explore our tutorials</h2>
                      <p>Get help starting your project with our free library of high-quality developer tutorials.</p>
                    </IntroContainer>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col>
                    {/* eslint-disable-next-line max-len */}
                    <TutorialEntries.Deck tutorials={CommunityPage.getTutorialEntries(tutorialData).filter(tutorial => { return tutorial.lang === 'en' }).slice(0, 3)} className='flex-column flex-lg-row' />
                  </Col>
                </Row>
                <Row>
                  <Col className='text-center'>
                    <Link classNames='btn btn-outline-black mt-0 mt-lg-3' to='/tutorials'>
                      View all tutorials
                      <i className='fa fa-long-arrow-right ml-2' />
                    </Link>
                  </Col>
                </Row>
              </Container>
            </section>
            <SupplementSection color='light-grey' />
            <section className='py-5 bg-gray-300'>
              <Container>
                <Row>
                  <Col xl={12}>
                    <h2 className='mb-5 mb-lg-1 text-center'>How to Contribute</h2>
                  </Col>
                </Row>
              </Container>
              <Container>{this.renderHighlightBlocks()}</Container>
              <Container className='my-5'>
                <Row>
                  <Col xl={12}>
                    <ContentContainer className='p-3 shadow-lg border-0 rounded'>
                      <Content>
                        <Row className='align-items-center'>
                          <Col xl={6}>
                            <h2 className='mt-0'>Ready for take off</h2>
                            <p>
                              Be a part of the community and contribute. Do it for the money. Do it for the bragging
                              rights. And do it to teach others!
                            </p>
                          </Col>
                          {/* eslint-disable-next-line max-len */}
                          <Col xl={6} className='d-flex flex-wrap justify-content-between justify-content-md-end align-items-center mt-3 mt-lg-0'>
                            <a href='https://github.com/hetzneronline/community-content' target='_blank' className='btn btn-primary mb-2 mb-lg-0'>
                              Write an article
                              <i className='fa fa-external-link ml-2' />
                            </a>
                            <Link to='/tutorials' classNames='btn btn-outline-black ml-0 ml-sm-1 ml-md-4 mb-2 mb-lg-0'>
                              View all tutorials
                            </Link>
                          </Col>
                        </Row>
                      </Content>
                    </ContentContainer>
                  </Col>
                </Row>
              </Container>
            </section>
          </main>
        </Layout>
      )}
    />
  }
}

export default CommunityPage
