import * as PropTypes from 'prop-types'
import React from 'react'
import defaultAvatar from '../../images/default_avatar.png'

const IMAGE_SIZE = 50

export function AuthorClassifiedName (props) {
  return <div className={['ml-lg-4', props.className].join(' ')}>
    <h6 className={['d-none d-lg-block text-transform-none m-0', props.classNameTitle].join(' ')}> Author</h6>
    <span className='d-block'>
      {props.name}
      { props.profileURL && <i className='fa fa-github ml-1' />}
    </span>
  </div>
}

AuthorClassifiedName.propTypes = { author: PropTypes.string }

export function AuthorImage (props) {
  let classnames = props.className || ''

  let avatarSrc = defaultAvatar
  if (props.src) {
    const url = new URL(props.src)
    url.searchParams.set('s', IMAGE_SIZE.toString(10))
    avatarSrc = url.toString()
  }

  return (
    <img
      className={'d-block rounded-circle ' + classnames}
      src={avatarSrc}
      alt='profile picture'
      width={props.width || IMAGE_SIZE}
      height={props.height || IMAGE_SIZE}
    />
  )
}

export function Author (props) {
  let styles = props.className || ''
  return <div className={'d-lg-flex align-items-center ' + styles}>
    <AuthorImage src={props.src} className={props.authorImgClassName} />
    <AuthorClassifiedName name={props.name}
      profileURL={props.profileURL}
    />
  </div>
}

Author.propTypes = {
  src: PropTypes.url,
  name: PropTypes.string,
  authorImgClassName: PropTypes.string,
  profileURL: PropTypes.string
}
