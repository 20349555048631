import React from 'react'
import { Container, Row, Col } from '@headtrip/components-react'

const IntroContainer = props => {
  return <Container>
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className='text-center mb-4'>
          {props.children}
        </div>
      </Col>
    </Row>
  </Container>
}

export default IntroContainer
