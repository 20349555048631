import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

function ContentContainer ({ className, children, onClick }) {
  const classNames = className || ''
  let handleClick = e => {
    if (onClick !== undefined) onClick(e)
  }
  return <div className={`content-container ${classNames}`} onClick={e => handleClick(e)}>
    {children}
  </div>
}

ContentContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func
}

function Content ({ className, children }) {
  const classNames = className || ''
  return <div className={`content ${classNames}`}>
    {children}
  </div>
}

Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}

function Headline ({ children }) {
  return <span className='h5 m-0 text-uppercase'>{children}</span>
}

Headline.propTypes = {
  children: PropTypes.any
}

function AdditionalContainer ({ isBig, color, className, children }) {
  let returnClassNames = () => {
    return classnames({
      'p-3': isBig
    }, className)
  }
  return <div className={`mb-0 px-3 py-2 additional-container-${color} ${returnClassNames()}`}>
    {children}
  </div>
}

AdditionalContainer.propTypes = {
  color: PropTypes.oneOf(['black', 'white', 'yellow', 'light-grey', 'blue', 'red']).isRequired,
  isBig: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string
}

export {
  ContentContainer,
  Content,
  Headline,
  AdditionalContainer
}
